enum RequestType {
    GET,
    POST,
    PUT,
    DELETE
};

const requestUrl = process.env.REACT_APP_BACKEND_URL;

/**
 * @description This currently just works for json endpoints, in the future create separate request methods that can handle images, videos (blobs) etc
 */
const requestJSONUnknown = (endpoint: string, type: RequestType, body?: any) => new Promise<any>((resolve, reject) => {
    fetch(`${requestUrl}/${endpoint}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("payload")}`,
            "Content-Type": "application/json"
        },
        method: RequestType[Number(type)],
        body: body != null ? JSON.stringify(body) : null
    })
        .then<any>((res) => (
            new Promise<any>(async (_res, _rej) => {
                //authentication error
                if (res.status === 401) {
                    localStorage.setItem("payload", null);
                    return _rej(await res.json());
                }

                _res(await res.json());
            })
        ))
        .then(res => resolve(res))
        .catch(reject)
});

/**
 * @description This currently just works for json endpoints, in the future create separate request methods that can handle images, videos (blobs) etc
 */
const requestJson = <T>(classType: new () => T, endpoint: string, type: RequestType, body?: any) => new Promise<T>((resolve, reject) => {
    fetch(`${requestUrl}/${endpoint}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("payload")}`,
            "Content-Type": "application/json"
        },
        method: RequestType[Number(type)],
        body: body != null ? JSON.stringify(body) : null
    })
        .then((res) => (
            new Promise<T>(async (_res, _rej) => {
                //authentication error
                if (res.status === 401) {
                    localStorage.setItem("payload", null);
                    return _rej(await res.json());
                }

                _res(res.json());
            })
        ))
        .then(res => resolve(Object.assign(new classType(), res)))
        .catch(reject)
});

export { requestJson, requestJSONUnknown, RequestType };