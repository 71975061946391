import React, { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import LevelCard from './components/LevelCard';
import { Button } from '@mui/material';
import { ForumMode } from '../../util/forumMode';
import { LevelStageCreateModal } from './components/LevelStageCreateModal';
import { FetchHandler } from '../../util/fetchHandler';
import LevelStage from '../../Models/LevelModel/LevelStage';

const LevelPage = (props: any) => {

    const [forumMode, setForumMode] = useState(ForumMode.Create);
    const [showModal, setShowModal] = useState(false);

    const levelStages = useAppSelector(state => state.playFabDataCache.levelStages);

    const ToggleModal = (val: boolean) => {
        setShowModal(val);
    }

    const CreateNewLevelStage = () => {
        setForumMode(ForumMode.Create);
        setShowModal(true);
    }

    const DeleteLevelStage = (stage: LevelStage) => {
        FetchHandler.DeleteLevelStageRequest(stage)
            .then(() => FetchHandler.getRewards())
            .catch(console.error);
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h1 className="fw-bold">Levels</h1>

                <Button variant="contained" onClick={CreateNewLevelStage}>
                    Add New Level Stage
                </Button>
            </div>

            <hr />

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignContent: "center" }}>
                {
                    levelStages.map(stage => <LevelCard key={stage.id} levelStage={stage} callbackEdit={() => { }} callbackDelete={() => DeleteLevelStage(stage)} />)
                }
            </div>

            {
                showModal && forumMode === ForumMode.Create ? <LevelStageCreateModal toggleModal={ToggleModal} /> : <></>
            }
        </>
    )
}

export default LevelPage;