import React from 'react';

import { RewardObject } from '../../../Models/RewardsModel/RewardObject';

export class BattlePassRewardProps {
    public rewards: RewardObject[] = null;
    public index: number;
    public title?: string;
}

const BattlePassReward = ({ rewards, index, title }: BattlePassRewardProps) => {
    return (
        <div>
            {
                title ?
                (
                    <h6 style={{ textDecorationLine: "underline" }}>
                        {`${index + 1}. ${title}`}
                    </h6>
                )
                :
                <></>
            }
            <p>
                {rewards.map(reward => reward.rewardName).join(", ")}
            </p>
        </div>
    )
};

export default BattlePassReward;