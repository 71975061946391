import React, { useState } from 'react';
import { useAppSelector } from '../../store/hooks';

import BattlePassPreview from './components/BattlePassPreview';

import BattlePassObject from '../../Models/BattlePassModel/BattlePassObject';

import { Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { BattlePassModal } from './components/BattlePassModal';
import { ForumMode } from '../../util/forumMode';
import { BattlePassCreateModal } from './components/BattlePassCreateModal';
import { FetchHandler } from '../../util/fetchHandler';

const BattlePassPage = () => {
    const [forumMode, setForumMode] = useState(ForumMode.Create);
    const [showModal, setShowModal] = useState(false);

    const [selectedBattlepass, setSelectedBattlepass] = useState<BattlePassObject>(null);

    const battlePasses = useAppSelector(state => state.battlePassCache.battlePasses);

    if (selectedBattlepass === null && battlePasses.length > 0) {
        setSelectedBattlepass(battlePasses[0]);
    }

    const selectNewBattlePass = (battlePass: BattlePassObject) => {
        setSelectedBattlepass(battlePass);
    }

    const DeletePass = (battlepass: BattlePassObject) => {
        FetchHandler.DeleteBattlepassRequest(battlepass)
            .then(() => FetchHandler.getRewards())
            .then(() => {
                if (battlepass === selectedBattlepass) {
                    selectNewBattlePass(battlePasses.length > 0 ? battlePasses[0] : null);
                }
            })
            .catch(console.error);
    }

    const ToggleModal = (val: boolean) => {
        if (!val) {
            setSelectedBattlepass(null);
        }

        setShowModal(val);
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h1>BattlePasses</h1>

                <FormControl sx={{ minWidth: "200px" }}>
                    <InputLabel id="battlePassSelection">Battlepass Selection</InputLabel>
                    <Select labelId='battlePassSelection'
                        value={selectedBattlepass !== null ? selectedBattlepass.id : ""}
                        onChange={(ev) => selectNewBattlePass(battlePasses.find(pass => pass.id === ev.target.value))}
                        label="Battlepass Selection"
                    >
                        {
                            battlePasses.map((pass) => (
                                <MenuItem key={pass.id} value={pass.id}>{pass.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <div style={{ gridArea: "actions" }}>
                    <Button variant="contained" onClick={() => {
                        setForumMode(ForumMode.Create);
                        ToggleModal(true);
                    }}>
                        Add new BattlePass
                    </Button>
                </div>
            </div>

            <hr />

            {
                selectedBattlepass != null ? <BattlePassPreview DeletePass={DeletePass} battlePass={selectedBattlepass} setForumMode={setForumMode} toggleModal={ToggleModal} /> : <></>
            }

            {
                showModal && forumMode === ForumMode.Edit ? <BattlePassModal toggleModal={ToggleModal} currentBattlePass={selectedBattlepass} /> : <></>
            }

            {
                showModal && forumMode === ForumMode.Create ? <BattlePassCreateModal toggleModal={ToggleModal} /> : <></>
            }
        </>
    )
}

export default BattlePassPage;