import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { RewardObject } from "../../../Models/RewardsModel/RewardObject";
import { useAppSelector } from "../../../store/hooks";

export class RewardPreviewProps {
    public index: number = 0;
    public rewardObject: RewardObject = null;
    public deleteReward: (reward: string, index: number) => void;
    public updateReward?: (reward: string, index: number) => void;
    public editReward?: () => void;
};

const RewardPreviewStyle: React.CSSProperties = {
    display: "grid",
    gridGap: "20px",
    margin: "10px",
    gridTemplateAreas: `'name reward amount delete edit'`,
    gridTemplateColumns: `20% 20% 15% 15% 15%`,
    gridTemplateRows: `auto`
};

const RewardPreview = (props: RewardPreviewProps) => {

    const rewards = useAppSelector(selector => selector.rewardsCache.rewards);

    const handleRewardStageChange = (rewardID: string) => {
        if (props.updateReward !== null) {
            props.updateReward(rewardID, props.index);
        }
    }

    return (
        <div style={RewardPreviewStyle}>
            <FormControl sx={{ minWidth: "150px" }}>
                <InputLabel id="reardSelection">Reward</InputLabel>
                <Select labelId='reardSelection'
                    value={props.rewardObject ? props.rewardObject.rewardID : ""}
                    onChange={(ev) => handleRewardStageChange(ev.target.value)}
                    label="Reward Pool"
                    disabled={!props.updateReward}
                >
                    {
                        rewards.map(reward => (
                            <MenuItem key={reward.rewardID} value={reward.rewardID}>{reward.rewardName}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <TextField sx={{ gridArea: "reward" }} label="ItemID" disabled value={props.rewardObject.itemID} />
            <TextField type="number" sx={{ gridArea: "amount" }} disabled label="Amount" value={props.rewardObject.amount} />
            <Button sx={{ gridArea: "delete" }} onClick={() => props.deleteReward(props.rewardObject ? props.rewardObject.rewardID : "", props.index)} variant='outlined' color="error">Delete</Button>
            <Button sx={{ gridArea: "edit" }} onClick={() => props.editReward()} variant='outlined'>Edit</Button>
        </div>
    )
}

export { RewardPreview };