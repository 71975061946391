import React, { MouseEventHandler } from "react";

import { Button, Card } from "@mui/material";
import { RewardObject } from "../../../Models/RewardsModel/RewardObject";

export class MissionCardProps {
    public rewardObject: RewardObject = null;
    public callbackEdit: MouseEventHandler<HTMLButtonElement> = () => { };
    public callbackDelete: MouseEventHandler<HTMLButtonElement> = () => { };
}

const RewardCard = (props: MissionCardProps) => {
    return (
        <Card className="card m-1" key={props.rewardObject.rewardID}>
            <div className="card-body">
                <h5 className="card-title">{props.rewardObject.rewardName}</h5>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <p>{props.rewardObject.rewardName}</p>
                    <p>Item: {props.rewardObject.itemID}</p>
                    <p>Is Premium Reward: {props.rewardObject.isPremiumReward ? "true" : "false"}</p>
                </div>

                <hr />

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button type={"button"} variant="contained" onClick={props.callbackEdit}>Edit</Button>
                    <Button type={"button"} variant="outlined" color="error" onClick={props.callbackDelete}>Delete</Button>
                </div>
            </div>
        </Card>
    );
}

export default RewardCard;