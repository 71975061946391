import React, { useState } from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Dialog, DialogActions, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ForumMode } from '../../../util/forumMode';
import { RewardProfile } from '../../../Models/RewardsModel/RewardProfile';
import { RewardType } from '../../../Models/RewardsModel/RewardObject';
import { RewardParameterType, RewardsParameters } from '../../../Models/RewardParameters/RewardParameters';
import { FetchHandler } from '../../../util/fetchHandler';

export class RewardProfileModalProps {
    public toggleModal: (val: boolean) => void;
    public currentProfile: RewardProfile;
    public forumMode: ForumMode;
};

export class RewardParameterProps {
    public parameter: RewardsParameters;
    public updateParameters: (parameter: RewardsParameters) => void;
}

const SectionStyle: React.CSSProperties = {
    margin: "15px",
    padding: "15px",
    borderRadius: "15px"
};

const RewardProfileStyle: React.CSSProperties = {
    ...SectionStyle,
    display: "grid",
    gridTemplateAreas: `"name" "free" "premium"`,
    gridTemplateColumns: `100%`,
    gridAutoRows: "auto",
    gridGap: "20px",
    overflowY: "scroll"
};

const RewardProfileModal = ({ currentProfile, toggleModal, forumMode }: RewardProfileModalProps) => {
    //anything that is not a primitive type needs to be copied and needs to create new instances of it
    const [profileName, setProfileName] = useState(currentProfile.profileName);
    const [parameters, setParameters] = useState(currentProfile.parameters.map(p => p));

    const CreateRewardProfile = () => {
        const tempProfile = new RewardProfile();

        tempProfile.profileID = currentProfile.profileID;
        tempProfile.profileName = profileName;
        tempProfile.parameters = parameters;

        return tempProfile;
    }

    const SaveProfile = () => {
        const profile = CreateRewardProfile();

        if (forumMode === ForumMode.Create) {
            FetchHandler.CreateProfileRequest(profile)
                .catch(console.error);
        } else if (forumMode === ForumMode.Edit) {
            FetchHandler.EditProfileRequest(profile)
                .catch(console.error);
        }

        toggleModal(false);
    }

    return (
        <Dialog fullWidth={true} scroll="paper" sx={{ margin: "auto" }} onClose={() => toggleModal(false)} open={true}>
            <h3 style={{ margin: "20px", textAlign: "center" }}>{forumMode === ForumMode.Create ? "Create Profile" : "Update Profile"}</h3>
            <Card sx={RewardProfileStyle}>
                <TextField sx={{ gridArea: "name" }} onChange={(ev) => setProfileName(ev.target.value)} label="Reward Profile Name" value={profileName} required />

                <div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h4>Add new Reward Parameter</h4>
                        <Button onClick={() => setParameters(parameters => {
                            parameters.push(new RewardsParameters());
                            return parameters.map(p => p);
                        })}>Add Parameter</Button>
                    </div>

                    <div>
                        <br />
                        {
                            parameters.map((parameter, index) =>
                                <RewardParameter key={index} updateParameters={(param) => {
                                    setParameters(params => {
                                        params.splice(params.indexOf(param), 1);
                                        return params.map(p => p);
                                    })
                                }} parameter={parameter} />)
                        }
                    </div>
                </div>
            </Card>

            <DialogActions>
                <Button onClick={() => toggleModal(false)}>Close</Button>
                <Button onClick={() => SaveProfile()}>Save</Button>
            </DialogActions>

            <div style={{ minWidth: "100%" }}></div>
        </Dialog>
    );
}

const RewardParameter = ({ parameter, updateParameters }: RewardParameterProps) => {

    const [rewardType, setRewardType] = useState(parameter.rewardType);
    const [rewardItemType, setRewardItemType] = useState(parameter.rewardItemType);
    const [minAmount, setMinAmount] = useState(parameter.minAmount);
    const [maxAmount, setMaxAmount] = useState(parameter.maxAmount);

    if (parameter.rewardType !== rewardType) {
        setRewardType(parameter.rewardType);
    }

    if (parameter.minAmount !== minAmount) {
        setMinAmount(parameter.minAmount);
    }

    if (parameter.maxAmount !== maxAmount) {
        setMinAmount(parameter.maxAmount);
    }

    if (parameter.rewardItemType !== rewardItemType) {
        setRewardItemType(parameter.rewardItemType);
    }

    return (
        <>
            <div style={{ display: "grid", gridGap: "8px", gridTemplateAreas: "'rewardType rewardParameterType' 'min max' 'delete delete'", gridAutoColumns: "50% 50%" }}>
                <FormControl sx={{ gridArea: "rewardType" }}>
                    <InputLabel id="rewardType">Reward Type</InputLabel>
                    <Select labelId='rewardType' onChange={(ev) => setRewardType((prev) => parameter.rewardType = ev.target.value as RewardType)} value={rewardType} label="RewardType" required>
                        {
                            Object.keys(RewardType).filter(length => isNaN(Number(length))).map(length => (
                                <MenuItem key={RewardType[length]} value={RewardType[length]}>{length}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <FormControl sx={{ gridArea: "rewardParameterType" }}>
                    <InputLabel id="rewardItemType">Reward Item Type</InputLabel>
                    <Select labelId='rewardItemType' onChange={(ev) => setRewardItemType((prev) => parameter.rewardItemType = ev.target.value as RewardParameterType)} value={rewardItemType} label="RewardItemType" required>
                        {
                            Object.keys(RewardParameterType).filter(length => isNaN(Number(length))).map(length => (
                                <MenuItem key={RewardParameterType[length]} value={RewardParameterType[length]}>{length}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <TextField label={"Min Amount"} style={{ gridArea: "min" }} type='number' value={minAmount} onChange={(ev) => setMinAmount((prev) => parameter.minAmount = Number.parseInt(ev.target.value))} />
                <TextField label={"Max Amount"} style={{ gridArea: "max" }} type='number' value={maxAmount} onChange={(ev) => setMaxAmount((prev) => parameter.maxAmount = Number.parseInt(ev.target.value))} />

                <Button variant='contained' sx={{ gridArea: "delete" }} onClick={() => updateParameters(parameter)} color="error">Delete</Button>
            </div>
            <hr />
        </>
    );
}

export { RewardProfileModal };