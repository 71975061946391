import React, { useState } from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Dialog, DialogActions, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';

import { FetchHandler } from '../../../util/fetchHandler';
import LevelSettings from '../../../Models/LevelModel/LevelSettings';

export class LevelStageCreateModalProps {
    public toggleModal: (val: boolean) => void;
};

const SectionStyle: React.CSSProperties = {
    margin: "15px"
};

const LevelStageModalStyle: React.CSSProperties = {
    ...SectionStyle,
    padding: "15px",
    borderRadius: "15px",
    overflowY: "scroll"
};

const LevelStageCreateModal = ({ toggleModal }: LevelStageCreateModalProps) => {
    const profiles = useAppSelector(state => state.profilesCache.profiles);

    //anything that is not a primitive type needs to be copied and needs to create new instances of it
    const [levelStageName, setLevelStageName] = useState("");

    const [isActive, setIsActive] = useState(false);
    const [rewardProfile, setRewardProfile] = useState("");
    const [stepper, setStepper] = useState(2);
    const [increaser, setIncreaser] = useState(1);
    const [algorithm, setAlgorithm] = useState("{level} * 1000");
    const [maxLevel, setMaxLevel] = useState(100);

    const CreateSettings = () => {
        const settings = new LevelSettings();

        settings.name = levelStageName;
        settings.algorithm = algorithm;
        settings.maxLevel = maxLevel;
        settings.isActive = isActive;
        settings.rewardIncreaser = increaser;
        settings.rewardStepper = stepper;
        settings.rewardProfile = rewardProfile;

        return settings;
    }

    const SaveLevelStage = () => {
        const settings = CreateSettings();

        FetchHandler.CreateLevelStageRequest(settings, false)
            .then(() => FetchHandler.getRewards())
            .catch(console.error);

        toggleModal(false);
    }

    return (
        <Dialog fullWidth={true} scroll="paper" sx={{ margin: "auto" }} onClose={() => toggleModal(false)} open={true}>
            <h3 style={{ margin: "20px", textAlign: "center" }}>{"Create Level Stage"}</h3>
            <Card sx={LevelStageModalStyle}>
                <TextField sx={{ gridArea: "name", width: "100%" }} onChange={(ev) => setLevelStageName(ev.target.value)} label="Level Stage Name" value={levelStageName} required />
                <hr />
                <br />

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignContent: "center" }}>
                    <h4>Settings</h4>

                    <FormControl sx={{ gridArea: "rewardProfile" }}>
                        <InputLabel id="rewardProfile">Reward Profile</InputLabel>
                        <Select style={{ marginLeft: "15px", marginRight: "15px" }} labelId='rewardProfile' onChange={(ev) => setRewardProfile(ev.target.value)} value={rewardProfile} label="Reward Profile" required>
                            {
                                profiles.map(profile => (
                                    <MenuItem key={profile.profileID} value={profile.profileID}>{profile.profileName}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    
                    <FormControlLabel style={SectionStyle} control={<Switch onChange={(ev, val) => setIsActive(val)} checked={isActive} />} label="Is Active" />
                    <TextField style={SectionStyle} type="number" label="Level Stepper (Every n level gets rewards)" onChange={(ev) => setStepper(Number.parseInt(ev.target.value))} value={stepper} />
                    <TextField style={SectionStyle} type="number" label="Level Increaser (Every reward * level * increaser)" onChange={(ev) => setIncreaser(Number.parseInt(ev.target.value))} value={increaser} />
                    <TextField style={SectionStyle} label="Algorithm to generate levels" onChange={(ev) => setAlgorithm(ev.target.value)} value={algorithm} />
                    <TextField style={SectionStyle} type="number" label="Max Level" onChange={(ev) => setMaxLevel(Number.parseInt(ev.target.value))} value={maxLevel} />
                </div>
            </Card>

            <DialogActions>
                <Button onClick={() => toggleModal(false)}>Close</Button>
                <Button onClick={() => {
                    if (!levelStageName) {
                        return;
                    }

                    SaveLevelStage();
                }}>Create</Button>
            </DialogActions>

            <div style={{ minWidth: "100%" }}></div>
        </Dialog>
    );
}

export { LevelStageCreateModal };