export enum RewardType {
    Currency,
    Item,
    PlayerStatistic
};

export enum Currency {
    WC,
    WG
};

export enum PlayerStatistic {
    XP,
    WPXP
};

export class RewardObject {
    public rewardName: string;
    public rewardID: string;
    public itemID: string;
    public rewardParentID: string;
    public rewardType: RewardType = RewardType.Currency;
    public amount: number = 0;
    public isPremiumReward: boolean = false;
};