import React, { useState } from 'react';

import { useAppSelector } from '../../store/hooks';

import { Button } from '@mui/material';
import { ForumMode } from '../../util/forumMode';
import { RewardObject } from '../../Models/RewardsModel/RewardObject';
import RewardCard from './components/RewardsCardComponent';
import { RewardsModal } from './components/RewardsModal';
import { FetchHandler } from '../../util/fetchHandler';

const RewardsPage = () => {
    const rewards = useAppSelector(state => state.rewardsCache.rewards);

    const [showModal, setShowModal] = useState(false);
    const [selectedReward, setSelectedReward] = useState<RewardObject>();
    const [forumMode, setForumMode] = useState(ForumMode.Create);

    const CreateNewReward = () => {
        setSelectedReward(new RewardObject());
        setForumMode(ForumMode.Create);
        setShowModal(true);
    }

    const EditReward = (reward: RewardObject) => {
        setSelectedReward(reward);
        setForumMode(ForumMode.Edit);
        setShowModal(true);
    }

    const ToggleModal = (val: boolean) => {
        if (!val) {
            setSelectedReward(null);
        }

        setShowModal(val);
    }

    const RenderModal = () => {
        return showModal ? <RewardsModal currentReward={selectedReward} toggleModal={ToggleModal} forumMode={forumMode} /> : <></>
    }

    return (
        <>
            <div id="RewardsList">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1 className="fw-bold">Rewards</h1>

                    <div>
                        <Button variant="contained" onClick={CreateNewReward}>
                            Add New Reward
                        </Button>
                    </div>
                </div>

                <hr />

                {
                    rewards.map((reward) =>
                        <RewardCard key={reward.rewardID} rewardObject={reward} callbackEdit={() => EditReward(reward)} callbackDelete={() => FetchHandler.DeleteRewardRequest(reward)} />
                    )
                }
            </div>

            <RenderModal />
        </>
    )
}

export { RewardsPage };