import React, { MouseEventHandler } from "react";

import { Button, Card } from "@mui/material";
import { RewardProfile } from "../../../Models/RewardsModel/RewardProfile";

export class RewardProfileCardProps {
    public profile: RewardProfile = null;
    public callbackEdit: MouseEventHandler<HTMLButtonElement> = () => { };
    public callbackDelete: MouseEventHandler<HTMLButtonElement> = () => { };
}

const RewardProfileCard = (props: RewardProfileCardProps) => {
    return (
        <Card className="card m-1" key={props.profile.profileID}>
            <div className="card-body">
                <h5 className="card-title">{props.profile.profileName}</h5>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <p>Name: {props.profile.profileName}</p>
                    <p>Parameter Count: {props.profile.parameters.length}</p>
                </div>

                <hr />

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button type={"button"} variant="contained" onClick={props.callbackEdit}>Edit</Button>
                    <Button type={"button"} variant="outlined" color="error" onClick={props.callbackDelete}>Delete</Button>
                </div>
            </div>
        </Card>
    );
}

export default RewardProfileCard;