import { RewardType } from "../RewardsModel/RewardObject";

export enum RewardParameterType
{
    WarCreditsCurrency,
    WarGoldCurrency,
    WarPassXP,
    PlayerXP,

    Operator,
    Weapon,
    Skin,
};

export class RewardsParameters
{
    /**
     * @description we want to identify the reward type (currency, item, etc), so when we compare we can know what to give
     */
    public rewardType: RewardType;

    /**
     * @description The reward parameter type
     */
    public rewardItemType: RewardParameterType;

    /**
     * @description Min Amount of items
     */
    public minAmount: number;

    /**
     * @description Max Amount of items
     */
    public maxAmount: number;
}