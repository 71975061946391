import { Card } from '@mui/material';
import React from 'react';

export class BundleCardProps {
    public style?: React.CSSProperties = {};
}

const BundleCard = (props: BundleCardProps) => {
    return (
        <Card sx={{ ...props.style }}>
            <div>
                <p>Lorem Ipsum</p>
            </div>
        </Card>
    )
}

export default BundleCard;