// Core imports
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

//Redux Init
import { Provider } from 'react-redux';
import { store } from './store/store';

// App imports
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { GoogleOAuthProvider } from '@react-oauth/google';

// root of the
const baseUrl: any = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement: any = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <GoogleOAuthProvider clientId="484091690032-qen4br2dlva2bui2qme4dmurh3t87k4v.apps.googleusercontent.com">
            <BrowserRouter basename={baseUrl}>
                <App />
            </BrowserRouter>
        </GoogleOAuthProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);