import React, { useState } from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Dialog, DialogActions, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { BattlePassSettings } from '../../../Models/BattlePassModel/BattlePassSettings';
import { FetchHandler } from '../../../util/fetchHandler';

export class BattlePassCreateModalProps {
    public toggleModal: (val: boolean) => void;
};

const SectionStyle: React.CSSProperties = {
    margin: "15px"
};

const BattlePassModalStyle: React.CSSProperties = {
    ...SectionStyle,
    padding: "15px",
    borderRadius: "15px",
    overflowY: "scroll"
};

const BattlePassCreateModal = ({ toggleModal }: BattlePassCreateModalProps) => {
    const profiles = useAppSelector(state => state.profilesCache.profiles);

    const [startDate, setStartDate] = useState(dayjs(new Date()));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [thresholdsCount, setThresholdsCount] = useState(5);
    const [stagesCount, setStagesCount] = useState(5);
    const [stageStepper, setStageStepper] = useState(500);
    const [thresholdRewardCount, setThresholdRewardCount] = useState(1);
    const [completionRewardCount, setCompletionRewardCount] = useState(1);
    const [thresholdStepper, setThresholdStepper] = useState(250);
    const [rewardProfile, setRewardProfile] = useState("");
    const [draft, setDraft] = useState(true);

    //anything that is not a primitive type needs to be copied and needs to create new instances of it
    const [battlePassName, setBattlePassName] = useState("");

    const CreateSettings = () => {
        const settings = new BattlePassSettings();

        settings.name = battlePassName;
        settings.isDraft = draft;
        settings.endDate = endDate.toISOString();
        settings.startDate = startDate.toISOString();
        settings.rewardProfile = rewardProfile;
        settings.thresholdsCount = thresholdsCount;
        settings.stagesCount = stagesCount;
        settings.stageStepper = stageStepper;
        settings.thresholdStepper = thresholdStepper;
        settings.completionRewardCount = completionRewardCount;
        settings.thresholdRewardCount = thresholdRewardCount;

        return settings;
    }

    const SaveBattlePass = () => {
        const battlePass = CreateSettings();

        FetchHandler.CreateBattlepassRequest(battlePass, false)
            .then(() => FetchHandler.getRewards())
            .catch(console.error);

        toggleModal(false);
    }

    return (
        <Dialog fullWidth={true} scroll="paper" sx={{ margin: "auto" }} onClose={() => toggleModal(false)} open={true}>
            <h3 style={{ margin: "20px", textAlign: "center" }}>{"Create Battlepass"}</h3>
            <Card sx={BattlePassModalStyle}>
                <TextField sx={{ gridArea: "name", width: "100%" }} onChange={(ev) => setBattlePassName(ev.target.value)} label="Battlepass name" value={battlePassName} required />
                <hr />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(ev) => setStartDate(ev)}
                            renderInput={props => <TextField {...props} />}
                        />

                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(ev) => setEndDate(ev)}
                            renderInput={props => <TextField {...props} />}
                        />
                    </div>
                </LocalizationProvider>
                <hr />
                <br />

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignContent: "center" }}>
                    <h4>Settings</h4>

                    <FormControl sx={{ gridArea: "rewardProfile" }}>
                        <InputLabel id="rewardProfile">Reward Profile</InputLabel>
                        <Select style={{ marginLeft: "15px", marginRight: "15px" }} labelId='rewardProfile' onChange={(ev) => setRewardProfile(ev.target.value)} value={rewardProfile} label="Reward Profile" required>
                            {
                                profiles.map(profile => (
                                    <MenuItem key={profile.profileID} value={profile.profileID}>{profile.profileName}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    
                    <FormControlLabel style={SectionStyle} control={<Switch onChange={(ev, val) => setDraft(val)} checked={draft} />} label="Draft" />
                    <TextField style={SectionStyle} type="number" label="Threshold Count" onChange={(ev) => setThresholdsCount(Number.parseInt(ev.target.value))} value={thresholdsCount} />
                    <TextField style={SectionStyle} type="number" label="Stages Count" onChange={(ev) => setStagesCount(Number.parseInt(ev.target.value))} value={stagesCount} />
                    <TextField style={SectionStyle} type="number" label="Stage Stepper" onChange={(ev) => setStageStepper(Number.parseInt(ev.target.value))} value={stageStepper} />
                    <TextField style={SectionStyle} type="number" label="Completion Reward Count" onChange={(ev) => setCompletionRewardCount(Number.parseInt(ev.target.value))} value={completionRewardCount} />
                    <TextField style={SectionStyle} type="number" label="Threshold Reward Count" onChange={(ev) => setThresholdRewardCount(Number.parseInt(ev.target.value))} value={thresholdRewardCount} />
                    <TextField style={SectionStyle} type="number" label="Threshold Stepper" onChange={(ev) => setThresholdStepper(Number.parseInt(ev.target.value))} value={thresholdStepper} />
                </div>
            </Card>

            <DialogActions>
                <Button onClick={() => toggleModal(false)}>Close</Button>
                <Button onClick={() => {
                    if (!battlePassName) {
                        return;
                    }

                    SaveBattlePass();
                }}>Save</Button>
            </DialogActions>

            <div style={{ minWidth: "100%" }}></div>
        </Dialog>
    );
}

export { BattlePassCreateModal };