import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import MissionObject from '../../Models/MissionModel/MissionObject';

interface IMissionCache {
    missions: Array<MissionObject>
};

const initialState: IMissionCache = {
    missions: []
};

export const missionCache = createSlice({
    name: "mission",
    initialState: initialState,
    reducers: {
        editMission: (state, mission: PayloadAction<MissionObject>) => {
            const index = state.missions.findIndex(mis => mis.missionGUID === mission.payload.missionGUID);

            state.missions[index] = mission.payload;
        },
        addMissions: (state, missions: PayloadAction<MissionObject[]>) => {
            state.missions.splice(0);

            missions.payload.forEach(mission => {
                state.missions.push(mission);
            });
        },
        addMission: (state, mission: PayloadAction<MissionObject>) => {
            state.missions.push(mission.payload);
        },
        removeMission: (state, mission: PayloadAction<MissionObject>) => {
            state.missions = state.missions.filter(m => m.missionGUID != mission.payload.missionGUID);
        }
    }
});

export const { addMission, removeMission, addMissions, editMission } = missionCache.actions;

export default missionCache.reducer;