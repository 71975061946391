import React, { useState } from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import { ForumMode } from '../../../util/forumMode';
import { Currency, PlayerStatistic, RewardObject, RewardType } from '../../../Models/RewardsModel/RewardObject';
import { FetchHandler } from '../../../util/fetchHandler';

export class RewardsModalProps {
    public toggleModal: (val: boolean) => void;
    public currentReward: RewardObject;
    public forumMode: ForumMode;
};

const SectionStyle: React.CSSProperties = {
    margin: "15px",
    padding: "15px",
    borderRadius: "15px"
};

const RewardStyle: React.CSSProperties = {
    ...SectionStyle,
    display: "grid",
    gridTemplateAreas: `"name" "isPremium" "amount" "rewardType" "items"`,
    gridTemplateColumns: `100%`,
    gridAutoRows: "auto",
    gridGap: "20px",
    overflowY: "scroll"
};

const RewardsModal = ({ currentReward, toggleModal, forumMode }: RewardsModalProps) => {
    const items = useAppSelector(state => state.playFabDataCache.catalogItems);

    //anything that is not a primitive type needs to be copied and needs to create new instances of it
    const [rewardName, setRewardName] = useState(currentReward.rewardName);
    const [amount, setAmount] = useState(currentReward.amount);
    const [rewardType, setRewardType] = useState(currentReward.rewardType);
    const [premiumReward, setPremiumReward] = useState(currentReward.isPremiumReward);
    const [itemID, setItemID] = useState(currentReward.itemID);

    const CreateRewardPool = () => {
        const tempReward = new RewardObject();

        tempReward.rewardID = currentReward.rewardID;
        tempReward.rewardParentID = currentReward.rewardParentID;
        tempReward.rewardName = rewardName;
        tempReward.amount = amount;
        tempReward.isPremiumReward = premiumReward;
        tempReward.rewardType = rewardType;
        tempReward.itemID = itemID;

        return tempReward;
    }

    const SaveReward = () => {
        const reward = CreateRewardPool();

        if (forumMode === ForumMode.Create) {
            FetchHandler.CreateRewardRequest(reward)
                .catch(console.error)
        } else if (forumMode === ForumMode.Edit) {
            FetchHandler.EditRewardRequest(reward)
                .catch(console.error)
        }

        toggleModal(false);
    }

    const GetItemOptions = () => {
        switch (rewardType) {
            case RewardType.Currency:
                return (
                    Object.keys(Currency).filter(length => isNaN(Number(length))).map(length => (
                        <MenuItem key={Currency[Currency[length]]} value={Currency[Currency[length]]}>{length}</MenuItem>
                    ))
                )
            case RewardType.PlayerStatistic:
                return (
                    Object.keys(PlayerStatistic).filter(length => isNaN(Number(length))).map(length => (
                        <MenuItem key={PlayerStatistic[PlayerStatistic[length]]} value={PlayerStatistic[PlayerStatistic[length]]}>{length}</MenuItem>
                    ))
                )
            case RewardType.Item:
                return (
                    items.map(item => (
                        <MenuItem key={item.itemId} value={item.itemId}>{item.displayName}</MenuItem>
                    ))
                )
        }
    }

    return (
        <Dialog fullWidth={true} scroll="paper" sx={{ margin: "auto" }} onClose={() => toggleModal(false)} open={true}>
            <h3 style={{ margin: "20px", textAlign: "center" }}>{forumMode === ForumMode.Create ? "Create Reward" : "Update Reward"}</h3>
            <Card sx={RewardStyle}>
                <FormControlLabel
                    sx={{ gridArea: "isPremium" }}
                    label="Is Premium"
                    control={<Checkbox checked={premiumReward} onChange={(ev, checked) => setPremiumReward(checked)} />}
                />

                <TextField sx={{ gridArea: "name" }} onChange={(ev) => setRewardName(ev.target.value)} label="Reward Name" value={rewardName} required />
                <TextField type="number" sx={{ gridArea: "amount" }} placeholder='0' onChange={(ev) => setAmount(Number.parseInt(ev.target.value))} label="Reward Amount" value={amount} required />

                <FormControl sx={{ gridArea: "rewardType" }}>
                    <InputLabel id="rewardItemType">Reward Item Type</InputLabel>
                    <Select labelId='rewardItemType' onChange={(ev) => setRewardType(ev.target.value as RewardType)} value={rewardType} label="Reward Type" required>
                        {
                            Object.keys(RewardType).filter(length => isNaN(Number(length))).map(length => (
                                <MenuItem key={RewardType[length]} value={RewardType[length]}>{length}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <FormControl sx={{ gridArea: "items" }}>
                    <InputLabel id="rewardItems">Item ID</InputLabel>
                    <Select labelId='rewardItems' onChange={(ev) => setItemID(ev.target.value)} value={itemID} label="Item ID" required>
                        {
                            GetItemOptions()
                        }
                    </Select>
                </FormControl>
            </Card>

            <DialogActions>
                <Button onClick={() => toggleModal(false)}>Close</Button>
                <Button onClick={() => SaveReward()}>Save</Button>
            </DialogActions>

            <div style={{ minWidth: "100%" }}></div>
        </Dialog>
    );
}

export { RewardsModal };