import React, { useState } from 'react';

import { useAppSelector } from '../../store/hooks';

import { Button } from '@mui/material';
import { ForumMode } from '../../util/forumMode';
import { RewardProfile } from '../../Models/RewardsModel/RewardProfile';
import { RewardProfileModal } from './components/RewardProfileModal';
import RewardProfileCard from './components/RewardProfileCard';
import { FetchHandler } from '../../util/fetchHandler';

const RewardProfilePage = () => {
    const rewardProfiles = useAppSelector(state => state.profilesCache.profiles);

    const [showModal, setShowModal] = useState(false);
    const [selectedRewardProfile, setSelectedRewardProfile] = useState<RewardProfile>();
    const [forumMode, setForumMode] = useState(ForumMode.Create);

    const CreateNewReward = () => {
        setSelectedRewardProfile(new RewardProfile());
        setForumMode(ForumMode.Create);
        setShowModal(true);
    }

    const EditReward = (profile: RewardProfile) => {
        setSelectedRewardProfile(profile);
        setForumMode(ForumMode.Edit);
        setShowModal(true);
    }

    const ToggleModal = (val: boolean) => {
        if (!val) {
            setSelectedRewardProfile(null);
        }

        setShowModal(val);
    }

    const RenderModal = () => {
        return showModal ? <RewardProfileModal currentProfile={selectedRewardProfile} toggleModal={ToggleModal} forumMode={forumMode} /> : <></>
    }

    return (
        <>
            <div id="RewardProfiles">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1 className="fw-bold">Reward Profiles</h1>

                    <div>
                        <Button variant="contained" onClick={CreateNewReward}>
                            Add New Profile
                        </Button>
                    </div>
                </div>

                <hr />

                {
                    rewardProfiles.map((p) =>
                        <RewardProfileCard key={p.profileID} profile={p} callbackEdit={() => EditReward(p)} callbackDelete={() => FetchHandler.DeleteRewardProfileRequest(p)} />
                    )
                }
            </div>

            <RenderModal />
        </>
    )
}

export { RewardProfilePage };