import { Card, CardContent } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../store/hooks';

export const Home = () => {
  const catalogItems = useAppSelector(state => state.playFabDataCache.catalogItems);
  const currencyItems = useAppSelector(state => state.playFabDataCache.currencies);

  const battlePasses = useAppSelector(state => state.battlePassCache.battlePasses);
  const rewards = useAppSelector(state => state.rewardsCache.rewards);
  const missions = useAppSelector(state => state.missionCache.missions);

  return (
    <div style={{ display: "grid", gridGap: "15px", gridTemplateAreas: "'playfab battlepass' 'missions rewards'" }}>
      <Card sx={{ gridArea: "playfab" }}>
        <CardContent>
          <h3>PlayFab Items</h3>

          <hr />

          <p>{currencyItems.length} Currencies</p>
          <p>{catalogItems.length} Catalog Items</p>
        </CardContent>
      </Card>

      <Card sx={{ gridArea: "battlepass" }}>
        <CardContent>
          <h3>Battlepasses</h3>

          <hr />

          <p>{battlePasses.length} BattlePasses</p>
        </CardContent>
      </Card>

      <Card sx={{ gridArea: "missions" }}>
        <CardContent>
          <h3>Missions</h3>

          <hr />

          <p>{missions.length} Missions</p>
        </CardContent>
      </Card>

      <Card sx={{ gridArea: "rewards" }}>
        <CardContent>
          <h3>Reward Pools</h3>

          <hr />

          <p>{rewards.length} Rewards</p>
        </CardContent>
      </Card>
    </div>
  );
}
