import React, { useState } from 'react';

import { useAppSelector } from '../../store/hooks';

import MissionCard from "./components/MissionCardComponent";
import { MissionModal } from './components/MissionModal';
import { Button } from '@mui/material';
import { requestJson, RequestType } from '../../util/request';
import MissionObject from '../../Models/MissionModel/MissionObject';
import { ForumMode } from '../../util/forumMode';
import { FetchHandler } from '../../util/fetchHandler';

const MissionsPage = () => {
    const missions = useAppSelector(state => state.missionCache.missions);

    const [showModal, setShowModal] = useState(false);
    const [selectedMission, setSelectedMission] = useState<MissionObject>(null);
    const [forumMode, setForumMode] = useState(ForumMode.Create);

    const CreateNewMission = () => {
        setSelectedMission(new MissionObject());
        setForumMode(ForumMode.Create);
        setShowModal(true);
    }

    const FetchRandomLimitedMissions = () => {
        requestJson<MissionObject[]>(Array<MissionObject>, 'BattlePass/FetchRandomLimitedMissionsForPlayer?maxFetch=6&maxPremiumFetch=4', RequestType.GET)
            .then(res => console.log(res))
            .catch(console.error)
    }

    const EditMission = (mission: MissionObject) => {
        setSelectedMission(mission);
        setForumMode(ForumMode.Edit);
        setShowModal(true);
    }

    const ToggleModal = (val: boolean) => {
        if (!val) {
            setSelectedMission(null);
        }

        setShowModal(val);
    }

    const RenderModal = () => {
        return showModal ? <MissionModal forumMode={forumMode} currentMission={selectedMission} toggleModal={ToggleModal} /> : <></>
    }

    return (
        <>
            <div id="MissionList">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1 className="fw-bold">Missions</h1>

                    <div>
                        <Button sx={{ marginRight: "10px" }} variant="outlined" onClick={FetchRandomLimitedMissions}>
                            Fetch Random Limited Missions
                        </Button>

                        <Button variant="contained" onClick={CreateNewMission}>
                            Add New Mission
                        </Button>
                    </div>
                </div>

                <hr />

                {
                    missions.map((mission) =>
                        <MissionCard key={mission.missionGUID} missionObject={mission} callbackEdit={() => EditMission(mission)} callbackDelete={() => FetchHandler.DeleteMissionRequest(mission)} />
                    )
                }
            </div>

            <RenderModal />
        </>
    )
}

export { MissionsPage };