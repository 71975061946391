import { configureStore } from '@reduxjs/toolkit';

import missionCache from './missions/MissionCache';
import authenticationCache from './authenticator/Authenticator';
import battlePassCache from './battlePass/BattlePassCache';
import playFabDataCache from './playfab/PlayFabDataCache';
import rewardsCache from './rewards/RewardsCache';
import profilesCache from './profiles/RewardProfilesCache';
import loadingCache from './loading/LoadingCache';

export const store = configureStore({
    middleware: (getDefaultMiddleware) => (
       getDefaultMiddleware({
        serializableCheck: false
       })
    ),
    reducer: {
        missionCache,
        authenticationCache,
        battlePassCache,
        playFabDataCache,
        rewardsCache,
        profilesCache,
        loadingCache
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;