import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BattlePassObject from '../../Models/BattlePassModel/BattlePassObject';

interface IBattlePassCache {
    battlePasses: Array<BattlePassObject>
};

const initialState: IBattlePassCache = {
    battlePasses: []
};

export const battlePassCache = createSlice({
    name: "battlepasses",
    initialState: initialState,
    reducers: {
        editBattlePass: (state, battlepass: PayloadAction<BattlePassObject>) => {
            const index = state.battlePasses.findIndex(pass => pass.id === battlepass.payload.id);

            state.battlePasses[index] = battlepass.payload;
        },
        addBattlePasses: (state, battlepass: PayloadAction<BattlePassObject[]>) => {
            state.battlePasses.splice(0);

            battlepass.payload.forEach(mission => {
                state.battlePasses.push(mission);
            });
        },
        addBattlePass: (state, battlePass: PayloadAction<BattlePassObject>) => {
            state.battlePasses.push(battlePass.payload);
        },
        removeBattlePass: (state, battlePass: PayloadAction<BattlePassObject>) => {
            state.battlePasses = state.battlePasses.filter(pass => pass.id != battlePass.payload.id);
        }
    }
});

export const { addBattlePass, removeBattlePass, addBattlePasses, editBattlePass } = battlePassCache.actions;

export default battlePassCache.reducer;