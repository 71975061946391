enum MissionTags
{
    // Actions
    Kills,
    Assists,
    Revives,
    Deaths,
    Reinforce,
    Plant,
    Defuse,

    // Acquire
    Points,
    Damaged,

    // Situations
    Round,
    Match,
    Win,
    Loss,

    // Player Type
    Defender,
    Attacker,
    Neutral,
    Bot,

    // Modes
    Bomb,
    Intel,
    SecureArea,
    FreeForAll,
    TeamDeathMatch,

    // Weapons
    Primary,
    Secondary,
    Gear,
    Ability,
    Melee,
    Explosive
}

export default MissionTags;