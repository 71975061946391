import { CatalogItem } from "../PlayFab/CatalogItem";
import { VirtualCurrencyData } from "../PlayFab/VirtualCurrencyData";
import Bundle from "./Bundle";

export class ContentResultResponse {
    
    public catalogItems: Array<CatalogItem> = new Array<CatalogItem>();

    public bundles: Array<Bundle> = new Array<Bundle>();

    public virtualCurrencies: Array<VirtualCurrencyData> = new Array<VirtualCurrencyData>();
}