import React from 'react';
import { useAppSelector } from '../../store/hooks';
import BundleTable from './components/BundleTable';
import CosmeticTable from './components/CosmeticTable';
import BundleCard from './components/BundleCard';

const BundlePreviewStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateAreas: "'featured random1 random2'",
    gridTemplateColumns: "36% 27% 27%",
    gridGap: "5%"
}

const BundlePage = () => {

    const bundles = useAppSelector(store => store.playFabDataCache.catalogItems)
        .filter(item => item.bundle);

    // we specify the catalog vcersion since we only want to show item from the cosmetics store
    const cosmetics = useAppSelector(store => store.playFabDataCache.catalogItems)
        .filter(item => !item.bundle && item.catalogVersion === "IGC");

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h1 className="fw-bold">Bundles</h1>
            </div>

            <div style={BundlePreviewStyle}>
                <BundleCard style={{ gridArea: "featured" }} />
                <BundleCard style={{ gridArea: "random1" }} />
                <BundleCard style={{ gridArea: "random2" }} />
            </div>

            <hr />

            <BundleTable bundles={bundles} />

            <br />

            <CosmeticTable cosmetics={cosmetics} />
        </div>
    )
}

export default BundlePage;