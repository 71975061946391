import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './pages/Layout';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { FetchHandler } from './util/fetchHandler';

export default function App() {
    const myTheme = createTheme({

        // Theme settings, I will have it default on dark theme for now
        palette: {
            "mode": "dark"
        }
    });

    FetchHandler.setupDispatch();

    return (
        <ThemeProvider theme={myTheme}>
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </Layout>
        </ThemeProvider>
    );
}
