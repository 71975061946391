import { MissionLength } from './MissionGenericEnums';
import MissionTags from './MissionTagEnum';

export default class MissionObject {
    public premiumMission: boolean = false;
    public isDraft: boolean = false;
    public missionGUID: string = "";
    public missionName: string = "";
    public missionDescription: string = "";
    public missionProgressMax: number = 0;
    public missionLength: MissionLength = MissionLength.None;
    public conditionTagsList: Array<MissionTags> = [];
    public rewardObjects: Array<string> = [];
    public rewardProfileID: string;
    public titleLocalizationID: number;
    public descriptionLocalizationID: number;
    public sessionMission: boolean;
};