export class BattlePassSettings {
    public name: string;
    public isDraft: boolean;
    public startDate: string;
    public endDate: string;
    public thresholdsCount: number;
    public stagesCount: number;
    public stageStepper: number;
    public completionRewardCount: number;
    public thresholdRewardCount: number;
    public thresholdStepper: number;
    public rewardProfile: string;
}