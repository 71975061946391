import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RewardProfile } from '../../Models/RewardsModel/RewardProfile';

interface IRewardProfilesCache {
    profiles: Array<RewardProfile>
};

const initialState: IRewardProfilesCache = {
    profiles: []
};

export const profilesCache = createSlice({
    name: "profiles",
    initialState: initialState,
    reducers: {
        editProfile: (state, profile: PayloadAction<RewardProfile>) => {
            const index = state.profiles.findIndex(r => r.profileID === profile.payload.profileID);

            if (index === -1) {
                return console.error(`Reward didn't find a match`);
            }

            state.profiles[index] = profile.payload;
        },
        addProfiles: (state, profiles: PayloadAction<RewardProfile[]>) => {
            state.profiles.splice(0);

            profiles.payload.forEach(reward => {
                state.profiles.push(reward);
            });
        },
        addProfile: (state, profile: PayloadAction<RewardProfile>) => {
            state.profiles.push(profile.payload);
        },
        removeProfile: (state, profile: PayloadAction<RewardProfile>) => {
            state.profiles = state.profiles.filter(p => p.profileID != profile.payload.profileID);
        }
    }
});

export const { addProfile, removeProfile, addProfiles, editProfile } = profilesCache.actions;

export default profilesCache.reducer;