import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CatalogItem } from '../../Models/PlayFab/CatalogItem';
import { VirtualCurrencyData } from '../../Models/PlayFab/VirtualCurrencyData';
import LevelStage from '../../Models/LevelModel/LevelStage';
import Bundle from '../../Models/ResponseModels/Bundle';

interface IPlayFabDataCache {
    catalogItems: Array<CatalogItem>,
    bundles: Array<Bundle>,
    currencies: Array<VirtualCurrencyData>,
    levelStages: LevelStage[],
};

const initialState: IPlayFabDataCache = {
    catalogItems: [],
    bundles: [],
    currencies: [],
    levelStages: []
};

export const playfabDataCache = createSlice({
    name: "playfabDataCache",
    initialState: initialState,
    reducers: {
        setCatalogItems: (state, catalogItems: PayloadAction<CatalogItem[]>) => {
            state.catalogItems.splice(0);

            catalogItems.payload.forEach(item => {
                state.catalogItems.push(item);
            });
        },
        setCurrencies: (state, currencies: PayloadAction<VirtualCurrencyData[]>) => {
            state.currencies.splice(0);

            currencies.payload.forEach(currency => {
                state.currencies.push(currency);
            });
        },
        setBundles: (state, bundles: PayloadAction<Bundle[]>) => {
            state.bundles.splice(0);

            bundles.payload.forEach(bundle => {
                state.bundles.push(bundle);
            });
        },
        setLevels: (state, levels: PayloadAction<LevelStage[]>) => {
            state.levelStages.splice(0);

            levels.payload.forEach(stage => {
                state.levelStages.push(stage);
            });
        },
        addLevel: (state, level: PayloadAction<LevelStage>) => {
            state.levelStages.push(level.payload);
        },
        removeLevel: (state, level: PayloadAction<LevelStage>) => {
            state.levelStages.splice(state.levelStages.indexOf(level.payload), 1);
        }
    }
});

export const { setCatalogItems, setBundles, setCurrencies, setLevels, addLevel, removeLevel } = playfabDataCache.actions;

export default playfabDataCache.reducer;