import React, { useState } from 'react';

import MissionTagEnum from "../../../Models/MissionModel/MissionTagEnum";

import Button from '@mui/material/Button';

import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Dialog, DialogActions, MenuItem, ToggleButton, FormControl, InputLabel, Switch } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import MissionObject from '../../../Models/MissionModel/MissionObject';
import { MissionLength } from '../../../Models/MissionModel/MissionGenericEnums';
import { ForumMode } from '../../../util/forumMode';
import { RewardPreview } from '../../RewardsPage/components/RewardPreview';
import { RewardObject } from '../../../Models/RewardsModel/RewardObject';
import { FetchHandler } from '../../../util/fetchHandler';

export class MissionModalProps {
    public toggleModal: (val: boolean) => void;
    public currentMission: MissionObject;
    public forumMode: ForumMode;
};

const SectionStyle: React.CSSProperties = {
    margin: "5px",
    padding: "15px",
    borderRadius: "15px"
}

const MissionCardStyle: React.CSSProperties = {
    ...SectionStyle,
    display: "grid",
    gridGap: "20px",
    gridTemplateAreas: `'name localizationTitle draft premium' 'desc desc localizationDesc session' 'progress progress type rewardProfile'`,
    gridTemplateColumns: `20% 30% 20% 20%`,
    gridTemplateRows: `auto`
};

const MissionModal = ({ currentMission, toggleModal, forumMode }: MissionModalProps) => {
    const rewards = useAppSelector(state => state.rewardsCache.rewards);
    const profiles = useAppSelector(state => state.profilesCache.profiles);

    //anything that is not a primitive type needs to be copied and needs to create new instances of it
    const [missionName, setMissionName] = useState(currentMission.missionName);
    const [missionDescription, setMissionDescription] = useState(currentMission.missionDescription);
    const [missionProgress, setMissionProgress] = useState(currentMission.missionProgressMax);
    const [draft, setDraft] = useState(currentMission.isDraft);
    const [premium, setPremium] = useState(currentMission.premiumMission);
    const [isSession, setIsSession] = useState(currentMission.sessionMission);

    const [missionLength, setMissionLength] = useState(currentMission.missionLength.toString());

    //Copy list entries
    const [selectedRewards, setSelectedRewards] = useState(currentMission.rewardObjects.map(reward => reward));
    const [selectedRewardProfile, setSelectedRewardProfile] = useState(currentMission.rewardProfileID);

    const [selectedTags, setSelectedTags] = useState(currentMission.conditionTagsList.map(c => c.toString()));

    // For the localization in Unity, so we can use our great Quickfetch
    const [localizationTitleID, setLocalizationTitleID] = useState(currentMission.titleLocalizationID);
    const [localizationDescriptionID, setLocalizationDescriptionID] = useState(currentMission.descriptionLocalizationID);

    const FilterRewards = (forceExcludeID: string = null) => {
        return rewards.filter(reward => !selectedRewards.includes(reward.rewardID) && reward.rewardID !== forceExcludeID);
    }

    const RetrieveDefaultReward = (forceExcludeID: string = null) => {
        const filteredRewards = FilterRewards(forceExcludeID);
        return filteredRewards.length > 0 ? filteredRewards[0] : null;
    }

    const [selectedReward, setSelectedReward] = useState<RewardObject>(RetrieveDefaultReward());

    const AddRewardToMission = () => {
        if (selectedRewards.includes(selectedReward.rewardID)) {
            return;
        }

        setSelectedRewards((prevRewards) => {
            prevRewards.push(selectedReward.rewardID);

            return prevRewards.splice(0);
        });

        setSelectedReward(RetrieveDefaultReward(selectedReward.rewardID));
    }

    const HasRewardsRemaining = (): boolean => {
        return FilterRewards().length > 0;
    }

    const HandleConditionalTagListChange = (event: React.MouseEvent<HTMLElement>, newCondition: string) => {
        const index: number = selectedTags.findIndex(tag => tag === newCondition);

        if (index === -1) {
            selectedTags.push(newCondition);
        } else {
            selectedTags.splice(index, 1);
        }

        setSelectedTags(selectedTags.splice(0));
    }

    const DeleteRewardFromMission = (reward: string) => {
        const index: number = selectedRewards.findIndex(selectedReward => selectedReward === reward);

        if (index !== -1) {
            setSelectedRewards((prevSeletedRewards) => {
                prevSeletedRewards.splice(index, 1);

                return prevSeletedRewards.splice(0);
            });

            setSelectedReward(RetrieveDefaultReward());
        }
    }

    const CreateMission = () => {
        const tempMission = new MissionObject();

        tempMission.missionName = missionName;
        tempMission.isDraft = draft;
        tempMission.premiumMission = premium;
        tempMission.missionLength = Number(missionLength);
        tempMission.conditionTagsList = selectedTags.map(tag => Number(tag));
        tempMission.missionDescription = missionDescription;
        tempMission.rewardObjects = selectedRewards;
        tempMission.missionProgressMax = missionProgress;
        tempMission.missionGUID = currentMission.missionGUID;
        tempMission.rewardObjects = selectedRewards;
        tempMission.rewardProfileID = selectedRewardProfile;
        tempMission.titleLocalizationID = localizationTitleID;
        tempMission.descriptionLocalizationID = localizationDescriptionID;
        tempMission.sessionMission = isSession;

        return tempMission;
    }

    const SaveMission = () => {
        const mission = CreateMission();

        if (forumMode === ForumMode.Create) {
            FetchHandler.CreateMissionRequest(mission)
                .catch(console.error);
        } else if (forumMode === ForumMode.Edit) {
            FetchHandler.EditMissionRequest(mission)
                .catch(console.error);
        }

        toggleModal(false);
    }

    return (
        <Dialog maxWidth={false} sx={{ width: "55% !important", margin: "auto" }} onClose={() => toggleModal(false)} open={true}>
            <h3 style={{ margin: "20px", textAlign: "center" }}>{forumMode === ForumMode.Create ? "Create Mission" : "Edit Mission"}</h3>

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "10px" }}>

                <Card sx={MissionCardStyle}>
                    <TextField sx={{ gridArea: "name" }} onChange={(ev) => setMissionName(ev.target.value)} label="Title" value={missionName} required />

                    <TextField type="number" sx={{ gridArea: "localizationTitle" }} onChange={(ev) => setLocalizationTitleID(parseInt(ev.target.value))} label="Title Localization ID" value={localizationTitleID} required />

                    <FormControlLabel sx={{ gridArea: "draft" }} control={<Switch onChange={(ev, val) => setDraft(val)} checked={draft} />} label="Draft" />

                    <FormControlLabel sx={{ gridArea: "premium" }} control={<Checkbox onChange={(ev, val) => setPremium(val)} checked={premium} />} label="Premium" />

                    <TextField sx={{ gridArea: "desc" }} onChange={(ev) => setMissionDescription(ev.target.value)} label="Mission Description" value={missionDescription} multiline required />

                    <TextField type="number" sx={{ gridArea: "localizationDesc" }} onChange={(ev) => setLocalizationDescriptionID(parseInt(ev.target.value))} label="Description Localization ID" value={localizationDescriptionID} required />

                    <FormControlLabel sx={{ gridArea: "session" }} control={<Checkbox onChange={(ev, val) => setIsSession(val)} checked={isSession} />} label="Is Session" />

                    <TextField sx={{ gridArea: "progress" }} onChange={(ev) => setMissionProgress(Number(ev.target.value))} type="number" value={missionProgress} label="Mission Progress Max" required />

                    <FormControl sx={{ gridArea: "type" }}>
                        <InputLabel id="missionLength">Mission Length</InputLabel>
                        <Select labelId='missionLength' onChange={(ev) => setMissionLength(ev.target.value)} value={missionLength} label="Mission Length" required>
                            {
                                Object.keys(MissionLength).filter(length => isNaN(Number(length))).map(length => (
                                    <MenuItem key={MissionLength[length]} value={MissionLength[length]}>{length}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl sx={{ gridArea: "rewardProfile" }}>
                        <InputLabel id="rewardProfile">Reward Profile</InputLabel>
                        <Select labelId='rewardProfile' onChange={(ev) => setSelectedRewardProfile(ev.target.value)} value={selectedRewardProfile} label="Reward Profile" required>
                            {
                                profiles.map(profile => (
                                    <MenuItem key={profile.profileID} value={profile.profileID}>{profile.profileName}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Card>

                <Card style={SectionStyle}>
                    <div style={{ gridArea: "tags" }} id="TagsList">
                        <h4 style={{ marginBottom: "10px" }}>Mission Tags</h4>

                        <div>
                            {
                                Object.keys(MissionTagEnum).filter(val => !isNaN(Number(val))).map((key, index) => (
                                    <ToggleButton sx={{ margin: "5px" }} onClick={HandleConditionalTagListChange} selected={selectedTags.includes(key)} value={key} key={MissionTagEnum[key]}>
                                        <span>{MissionTagEnum[key]}</span>
                                    </ToggleButton>
                                ))
                            }
                        </div>
                    </div>
                </Card>

                <Card style={SectionStyle}>
                    <div id="RewardForm">
                        <h4>Select Rewards</h4>
                        {
                            HasRewardsRemaining() ?
                                (
                                    <div style={{ margin: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <FormControl>
                                            <InputLabel id="rewardSelection">Rewards</InputLabel>
                                            <Select labelId='rewardSelection'
                                                onChange={(ev) => setSelectedReward(rewards.find(reward => reward.rewardID === ev.target.value))}
                                                value={selectedReward?.rewardID}
                                                label="Rewards" required
                                            >
                                                {
                                                    FilterRewards().map(reward => (
                                                        <MenuItem key={reward.rewardID} value={reward.rewardID}>{reward.rewardName}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>

                                        <div>
                                            <Button variant='outlined' onClick={AddRewardToMission}>Add New Reward</Button>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <h6>No more reward pools to select available</h6>
                                    </div>
                                )
                        }
                    </div>
                    <hr />

                    <div id="RewardsList">
                        <h4>Selected Rewards</h4>
                        {
                            selectedRewards.length ?
                                (
                                    <div>
                                        {
                                            selectedRewards.map((id, index) => (
                                                <RewardPreview key={id} index={index} deleteReward={DeleteRewardFromMission} rewardObject={rewards
                                                    .find(reward => reward.rewardID === id)
                                                } />
                                            ))
                                        }
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <h6>No Rewards selected</h6>
                                    </div>
                                )
                        }
                    </div>
                </Card>
            </div>

            <DialogActions>
                <Button onClick={() => toggleModal(false)}>Close</Button>
                <Button onClick={() => SaveMission()}>Save</Button>
            </DialogActions>

        </Dialog >
    );
}

export { MissionModal };