import React, { useState } from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Dialog, DialogActions, FormControlLabel, Switch } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import BattlePassObject from '../../../Models/BattlePassModel/BattlePassObject';
import BattlePassStage from '../../../Models/BattlePassModel/BattlePassStage';
import { RewardPreview } from '../../RewardsPage/components/RewardPreview';
import BattlePassStageReward from '../../../Models/BattlePassModel/BattlePassStageReward';
import { FetchHandler } from '../../../util/fetchHandler';

export class BattlePassModalProps {
    public toggleModal: (val: boolean) => void;
    public currentBattlePass: BattlePassObject;
};

const SectionStyle: React.CSSProperties = {
    margin: "15px",
    padding: "15px",
    borderRadius: "15px"
}

const BattlePassModalStyle: React.CSSProperties = {
    ...SectionStyle,
    overflowY: "scroll"
}

const BattlePassModal = ({ currentBattlePass, toggleModal }: BattlePassModalProps) => {
    const [startDate, setStartDate] = useState(dayjs(currentBattlePass.getStartDate()));
    const [endDate, setEndDate] = useState(dayjs(currentBattlePass.getEndDate()));
    const [stages, setStages] = useState(currentBattlePass.stages.map(stage => { return { ...stage } }));
    const [draft, setDraft] = useState(currentBattlePass.isDraft);

    //anything that is not a primitive type needs to be copied and needs to create new instances of it
    const [battlePassName, setBattlePassName] = useState(currentBattlePass.name);

    const CreateBattlePass = () => {
        const tempBattlePass = new BattlePassObject();

        tempBattlePass.id = currentBattlePass.id;
        tempBattlePass.name = battlePassName;
        tempBattlePass.endDate = endDate.toISOString();
        tempBattlePass.startDate = startDate.toISOString();
        tempBattlePass.stages = stages;
        tempBattlePass.isDraft = draft;

        return tempBattlePass;
    }

    const SaveBattlePass = () => {
        const battlePass = CreateBattlePass();

        FetchHandler.EditBattlepassRequest(battlePass)
            .catch(console.error);

        toggleModal(false);
    }

    const AddRewardStage = () => {
        setStages(prevStages => {
            prevStages.push(new BattlePassStage());

            return prevStages.splice(0);
        });
    }

    const DeleteStage = (index: number) => {
        setStages(prevStages => {
            prevStages.splice(index, 1);

            return prevStages.splice(0);
        });
    }

    const AddThreshold = (index: number) => {
        setStages(prevStages => {
            prevStages[index].rewardThresholds.push(new BattlePassStageReward());

            return prevStages.map(stage => stage);
        });
    }

    const UpdateStageThreshold = (index: number, value: string) => {
        setStages(prevStages => {
            prevStages[index].threshold = parseInt(value);

            return prevStages.map(stage => stage);
        });
    }

    return (
        <Dialog fullWidth={true} scroll="paper" sx={{ margin: "auto" }} onClose={() => toggleModal(false)} open={true}>
            <h3 style={{ margin: "20px", textAlign: "center" }}>Edit Battlepass</h3>
            <Card sx={BattlePassModalStyle}>
                <TextField sx={{ gridArea: "name", width: "100%" }} onChange={(ev) => setBattlePassName(ev.target.value)} label="Battlepass name" value={battlePassName} required />
                <FormControlLabel sx={{ gridArea: "draft" }} control={<Switch onChange={(ev, val) => setDraft(val)} checked={draft} />} label="Draft" />
                <hr />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(ev) => setStartDate(ev)}
                            renderInput={props => <TextField {...props} />}
                        />

                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(ev) => setEndDate(ev)}
                            renderInput={props => <TextField {...props} />}
                        />
                    </div>
                </LocalizationProvider>
                <hr />
                <br />

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h4>Set Stages</h4>
                    <Button onClick={AddRewardStage} variant='contained'>Add Stage</Button>
                </div>
                {
                    stages.map((stage, index) => (
                        <div key={stage.stageID}>
                            <hr />
                            <h5 style={{ margin: "15px 0px 15px 0px", textDecoration: "underline" }}>{index + 1}. Stage</h5>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <TextField type="number" label="Stage Threshold" value={stage.threshold} onChange={(ev) => UpdateStageThreshold(index, ev.target.value)} />
                                <Button variant='outlined' onClick={() => AddThreshold(index)}>Add Reward Threshold</Button>
                                <Button onClick={() => DeleteStage(index)} variant='outlined' color='error'>Delete</Button>
                            </div>

                            <StagePreview stage={stage} stageID={index + 1} />
                        </div>
                    ))
                }
            </Card>

            <DialogActions>
                <Button onClick={() => toggleModal(false)}>Close</Button>
                <Button onClick={() => {
                    if (!battlePassName) {
                        return;
                    }

                    SaveBattlePass();
                }}>Save</Button>
            </DialogActions>

            <div style={{ minWidth: "100%" }}></div>
        </Dialog>
    );
}

class StagePreviewProps {
    public stage: BattlePassStage;
    public stageID: number;
}

const StagePreview = ({ stage, stageID }: StagePreviewProps) => {
    const rewards = useAppSelector(state => state.rewardsCache.rewards);

    stage.stageID = stageID;

    const [currentStage, setCurrentStage] = useState(stageID);
    const [currentStageRewards, setCurrentStageRewards] = useState(stage.rewardThresholds.map(r => r));
    const [currentRewardsReachedRewards, setCurrentRewardsReachedRewards] = useState(stage.completionReward.map(r => r));

    if (currentStage !== stageID) {
        stage.stageID = stageID;
        setCurrentStage(stageID);
        setCurrentStageRewards(stage.rewardThresholds.map(r => r));
        setCurrentRewardsReachedRewards(stage.completionReward.map(r => r));
    }

    if (currentStageRewards.length !== stage.rewardThresholds.length) {
        setCurrentStageRewards(stage.rewardThresholds.map(r => r));
    }

    const handleRewardReachedChange = () => {
        const convertedVal = currentRewardsReachedRewards.map(r => r);
        convertedVal.push("");

        setCurrentRewardsReachedRewards(convertedVal);
        stage.completionReward = convertedVal;
    }

    const renderThresholdRewards = (stageIndex: number, rewardIDs: Array<string>) => {
        if (!rewardIDs || rewardIDs.length <= 0) {
            return (
                <h6 style={{ margin: "10px 0px" }}>No rewards to preview</h6>
            );
        }

        return rewardIDs.map((rewardID, index) => (
            <RewardPreview key={rewardID} rewardObject={rewards.find(r => r.rewardID === rewardID)} index={index} deleteReward={(id, indexOfReward) => {
                setCurrentStageRewards((prev) => {
                    prev[stageIndex].rewardIDs.splice(indexOfReward, 1);
                    return prev.map(reward => reward);
                })
            }} updateReward={(rewardID, indexOfReward) => {
                setCurrentStageRewards((prev) => {
                    prev[stageIndex].rewardIDs[indexOfReward] = rewardID;
                    return prev.map(reward => reward);
                })
            }} />
        ));
    }

    const AddRewardToThreshold = (index: number) => {
        setCurrentStageRewards((rewards) => {
            rewards[index].rewardIDs.push("");

            return rewards.map(reward => reward);
        })
    }

    const UpdateThreshold = (index: number, value: string) => {
        setCurrentStageRewards((rewards) => {
            rewards[index].threshold = parseInt(value);

            return rewards.map(reward => reward);
        })
    }

    const renderThresholds = () => {
        if (!currentStageRewards || currentStageRewards.length <= 0) {
            return (
                <div style={{ margin: "20px 0px" }}>
                    <h6 style={{ margin: "10px 0px" }}>No reward stages to preview</h6>
                    <hr />
                </div>
            );
        }

        return currentStageRewards.map((reward, index) => (
            <div key={index}>
                <p><b>{index + 1}. Threshold Reward Pools</b></p>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "20px 0px" }}>
                    <TextField label="Reward Threshold" type='number' value={reward.threshold} onChange={(ev) => UpdateThreshold(index, ev.target.value)} />
                    <Button variant="outlined" onClick={() => AddRewardToThreshold(index)}>Add New Reward</Button>
                    <Button color="error" variant="outlined" onClick={() => {
                        setCurrentStageRewards((stages) => {
                            stage.rewardThresholds.splice(index, 1);
                            stages.splice(index, 1);
                            return stages.map(stage => stage);
                        })
                    }}>Delete</Button>
                </div>
                {
                    renderThresholdRewards(index, reward.rewardIDs)
                }
                <hr />
            </div>
        ))
    }

    const renderStageReachedRewards = () => {
        if (currentRewardsReachedRewards.length <= 0) {
            return (<h6>No rewards to preview</h6>);
        }

        return currentRewardsReachedRewards.map((reward, index) => (
            <div key={reward}>
                <RewardPreview rewardObject={rewards.find(r => r.rewardID === reward)} index={index} deleteReward={() => {
                    setCurrentRewardsReachedRewards((prev) => {
                        prev.splice(index, 1);
                        return prev.map(r => r);
                    })
                }} updateReward={(rewardID, index) => {
                    setCurrentRewardsReachedRewards((prev) => {
                        prev[index] = rewardID;
                        return prev.map(r => r);
                    })
                }} />
            </div>
        ))
    }

    return (
        <div>
            <h5 style={{ margin: "20px 0px" }}>Threshold Reward Preview</h5>

            {
                renderThresholds()
            }

            <div style={{ margin: "10px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h5>Reaching Stage Rewards</h5>
                <Button variant='outlined' onClick={handleRewardReachedChange}>Add New Reward</Button>
            </div>

            {
                renderStageReachedRewards()
            }
        </div>
    );
}

export { BattlePassModal };