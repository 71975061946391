import BattlePassStage from "./BattlePassStage";

export default class BattlePassObject {
    public isDraft: boolean = true;
    public id: string = null;
    public name: string = null;
    public startDate: string = new Date().toISOString();
    public endDate: string = new Date().toISOString();
    public stages: BattlePassStage[] = new Array<BattlePassStage>();

    public getStartDate() {
        return new Date(this.startDate);
    }

    public getEndDate() {
        return new Date(this.endDate);
    }
}