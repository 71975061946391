import React, { MouseEventHandler } from "react";

import { Button, Card, Checkbox, FormControlLabel } from "@mui/material";
import MissionObject from "../../../Models/MissionModel/MissionObject";
import MissionTags from "../../../Models/MissionModel/MissionTagEnum";

export class MissionCardProps {
    public missionObject: MissionObject = null;
    public callbackEdit: MouseEventHandler<HTMLButtonElement> = () => { };
    public callbackDelete: MouseEventHandler<HTMLButtonElement> = () => { };
}

const MissionCard = ({ missionObject, callbackDelete, callbackEdit }: MissionCardProps) => {
    return (
        <Card className="card m-1" key={missionObject.missionGUID}>
            <div className="card-body">
                <h5 className="card-title">{missionObject.missionName}</h5>

                <div style={{ display: "grid", gridGap: "8px", gridTemplateAreas: "'description premium draft' 'conditions conditions rewards'", gridAutoColumns: "60% 20% 20%" }}>
                    <p style={{ gridArea: "description" }}>{missionObject.missionDescription}</p>
                    <FormControlLabel control={<Checkbox sx={{ gridArea: "premium" }} checked={missionObject.premiumMission} disabled/>} label="Is Premium" />
                    <FormControlLabel control={<Checkbox sx={{ gridArea: "draft" }} value={missionObject.isDraft} disabled/>} label="Is Draft" />
                    <p style={{ gridArea: "conditions" }}>Conditions: {missionObject.conditionTagsList.length <= 0 ? "No Conditions" : missionObject.conditionTagsList.map(tag => MissionTags[tag].toString()).join(', ')}</p>
                    <p style={{ gridArea: "rewards" }}>Rewards assigned: {missionObject.rewardObjects.length}</p>
                </div>

                <hr />

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button type={"button"} variant="contained" onClick={callbackEdit}>Edit</Button>
                    <Button type={"button"} variant="outlined" color="error" onClick={callbackDelete}>Delete</Button>
                </div>
            </div>
        </Card>
    );
}

export default MissionCard;