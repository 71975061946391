import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useState } from 'react';

import { CatalogItem } from '../../../Models/PlayFab/CatalogItem';

export class BundleTableProps {
    public bundles: CatalogItem[];
}

const BundleTable = (props: BundleTableProps) => {

    const [selectedBundles, setSelectedBundles] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const generateBundleRows = () => {
        let startIndex = rowsPerPage * page >= props.bundles.length ? props.bundles.length - 1 : rowsPerPage * page;

        return Array.from(props.bundles).splice(startIndex, rowsPerPage).map((item, index) => (
            <TableRow key={index}>
                <TableCell>
                    <Checkbox
                        onChange={() => changeSingleBundleSelectState(item.itemId)}
                        checked={selectedBundles.includes(item.itemId)}
                    />
                </TableCell>
                <TableCell>{item.displayName}</TableCell>
                <TableCell>{item.virtualCurrencyPrices["WG"]} WG</TableCell>
                <TableCell>{item.tags[0]}</TableCell>
                <TableCell>{item.bundle.bundledItems.length}</TableCell>
            </TableRow>
        ))
    }

    const changeSingleBundleSelectState = (itemID: string) => {
        if (selectedBundles.includes(itemID)) {
            setSelectedBundles((bundles) => {
                bundles.splice(bundles.indexOf(itemID), 1);
                return bundles.map(bundle => bundle);
            })
        } else {
            setSelectedBundles((bundles) => {
                bundles.push(itemID);
                return bundles.map(bundle => bundle);
            })
        }
    }

    const toggleAll = (ev) => {
        if (ev.target.checked) {
            setSelectedBundles(props.bundles.map(item => item.itemId));
        } else {
            setSelectedBundles([]);
        }
    }

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={selectedBundles.length > 0}
                                    onChange={toggleAll}
                                />
                            </TableCell>
                            <TableCell>Bundle Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Bundle Type</TableCell>
                            <TableCell>Items in Bundle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            generateBundleRows()
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.bundles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(ev, newPage) => setPage(newPage)}
                onRowsPerPageChange={(ev) => {
                    setRowsPerPage(parseInt(ev.target.value));
                    setPage(0);
                }}
            />
        </Paper>
    )
}

export default BundleTable;