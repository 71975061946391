import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ILoadingCache {
    isLoading: boolean
};

const initialState: ILoadingCache = {
    isLoading: false
};

export const loadingCache = createSlice({
    name: "loading",
    initialState: initialState,
    reducers: {
        setLoading: (state, loading: PayloadAction<boolean>) => {
            state.isLoading = loading.payload;
        }
    }
});

export const { setLoading } = loadingCache.actions;

export default loadingCache.reducer;