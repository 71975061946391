import { RewardsParameters } from "../RewardParameters/RewardParameters";

export class RewardProfile {

    /**
     * @description The Reward Profile ID
     */
    public profileID: string;

    /**
     * @description The name of the Profile
     */
    public profileName: string;

    /**
     * @description The parameters to use to generate rewards from this
     */
    public parameters: RewardsParameters[] = new Array<RewardsParameters>();
};