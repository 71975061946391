import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RewardObject } from '../../Models/RewardsModel/RewardObject';

interface IRewardsCache {
    rewards: Array<RewardObject>
};

const initialState: IRewardsCache = {
    rewards: []
};

export const rewardCache = createSlice({
    name: "rewards",
    initialState: initialState,
    reducers: {
        editReward: (state, reward: PayloadAction<RewardObject>) => {
            const index = state.rewards.findIndex(r => r.rewardID === reward.payload.rewardID);

            if (index === -1) {
                return console.error(`Reward didn't find a match`);
            }

            state.rewards[index] = reward.payload;
        },
        addRewards: (state, rewards: PayloadAction<RewardObject[]>) => {
            state.rewards.splice(0);

            rewards.payload.forEach(reward => {
                state.rewards.push(reward);
            });
        },
        addReward: (state, reward: PayloadAction<RewardObject>) => {
            state.rewards.push(reward.payload);
        },
        removeReward: (state, reward: PayloadAction<RewardObject>) => {
            state.rewards = state.rewards.filter(m => m.rewardID != reward.payload.rewardID);
        }
    }
});

export const { addReward, removeReward, addRewards, editReward } = rewardCache.actions;

export default rewardCache.reducer;