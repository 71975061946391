import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import BattlePassObject from '../../../Models/BattlePassModel/BattlePassObject';
import BattlePassReward from './BattlePassReward';
import { useAppSelector } from '../../../store/hooks';
import { ForumMode } from '../../../util/forumMode';

export class BattlePassPreviewProps {
    public battlePass: BattlePassObject = null;
    public toggleModal: (val: boolean) => void = null;
    public setForumMode: (val: ForumMode) => void = null;
    public DeletePass: (pass: BattlePassObject) => void = null;
};

const StyleOfRewardPreview: React.CSSProperties = {
    borderColor: "gray",
    margin: "10px",
    padding: "10px",
    borderRadius: "25px",
    borderWidth: "3px",
    borderStyle: "solid"
};

const BattlePassPreview = ({ battlePass, toggleModal, setForumMode, DeletePass }: BattlePassPreviewProps) => {
    const rewards = useAppSelector(state => state.rewardsCache.rewards);

    const generateGrid = () => {
        return `${reverseStages().map(stage => `'stagePrev stage${stage.stageID} rewards${stage.stageID}'`).join(' ')}`
    }

    const GenerateGridForBattlePass = (): React.CSSProperties => {
        return {
            display: "grid",
            gridTemplateAreas: `'stageInfo missionInfo reachingInfo'` + generateGrid(),
            gridTemplateColumns: `5% 70% 25%`,
            gridGap: "5px",
            padding: "10px",
            borderRadius: "20px"
        }
    }

    const EditPass = () => {
        setForumMode(ForumMode.Edit);
        toggleModal(true);
    }

    const reverseStages = () => {
        //return the reversed array, reverse would populate the main array, thats why we duplicate the array with Array.from
        return Array.from(battlePass.stages).reverse();
    }

    return (
        <div>
            <div style={{ display: "grid", gridTemplateAreas: `'empty title actions' 'content content content'`, gridAutoColumns: "10% 75% 15%" }}>
                <div style={{ width: "30%", gridColumnStart: "empty", gridColumnEnd: "title" }}>
                    <Card style={{ marginBottom: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <CardContent>
                            <h4>{battlePass.name}</h4>
                        </CardContent>
                    </Card>

                    <Card style={{ marginBottom: "10px" }}>
                        <CardContent>
                            <h6>Ends at: {battlePass.getEndDate().toUTCString()}</h6>
                        </CardContent>
                    </Card>
                </div>

                <div style={{ gridArea: "actions" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignContent: "center" }}>
                        <Button sx={{ margin: "5px" }} onClick={EditPass} variant="outlined">
                            Edit
                        </Button>
                        <Button sx={{ margin: "5px" }} onClick={() => DeletePass(battlePass)} variant="outlined" color="error">
                            Delete
                        </Button>
                    </div>
                </div>

                <div style={{ gridArea: "content", display: "grid", gridTemplateAreas: `'content'`, gridAutoColumns: "100%" }}>
                    <Card sx={{ gridArea: "content" }}>
                        <CardContent style={GenerateGridForBattlePass()}>
                            <h6 style={{ gridArea: "stageInfo", textAlign: "center" }}>Stages</h6>
                            <h6 style={{ gridArea: "missionInfo", textAlign: "center" }}>All Mission Rewards</h6>
                            <h6 style={{ gridArea: "reachingInfo", textAlign: "center" }}>All Reaching Rewards</h6>

                            {
                                reverseStages().map((stage) => (
                                    <>
                                        <div>
                                            <p style={{ gridArea: "stagePrev", margin: "10px", textAlign: "center", height: "50px" }}>Stage {stage.stageID}</p>
                                        </div>
                                        <div style={{ ...StyleOfRewardPreview, gridArea: `stage${stage.stageID}`, display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "0px 10px 0px 10px" }}>
                                            {
                                                stage.rewardThresholds.map((reward, index) => <BattlePassReward key={index} title='Mission' index={index} rewards={rewards.filter(r => reward.rewardIDs != null && reward.rewardIDs.includes(r.rewardID))} />)
                                            }
                                        </div>
                                        <div style={{ ...StyleOfRewardPreview, gridArea: `rewards${stage.stageID}`, display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "0px 10px 0px 10px" }}>
                                            {
                                                stage.completionReward.map((reward, index) => <BattlePassReward key={index} index={index} rewards={rewards.filter(r => r.rewardID === reward)} />)
                                            }
                                        </div>
                                    </>
                                ))
                            }
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default BattlePassPreview;