export default class LevelSettings {

    /**
    * @description The name of this level stage
    */
    public name: string;

    /** 
     * @description If this level should be the new active stage
     */
    public isActive: boolean;

    /**
     * @description The id of the reward profile used to generate the rewards
     */
    public rewardProfile: string;

    /**
     * @description This value decides what levels shall get rewards
     */
    public rewardStepper: number;

    /**
     * @description This value will be an extra increase, default is 1 otherwise it will look like (amount * level * increaser) 
     */
    public rewardIncreaser: number = 1;

    /**
    * @description The executable algorithm that generated these levels saved as a string
    */
    public algorithm: string;

    /**
     * @description The max level currently available
     */
    public maxLevel: number;
}