import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IAuthenticationCache {
    payload: string
};

const initialState: IAuthenticationCache = {
    payload: localStorage.getItem("payload")
};

export const authenticationCache = createSlice({
    name: "authentication",
    initialState: initialState,
    reducers: {
        setPayload: (state, authentication: PayloadAction<string>) => {
            localStorage.setItem("payload", authentication.payload);
            state.payload = authentication.payload;
        },
        deletePayload: (state, authentication: PayloadAction) => {
            localStorage.setItem("payload", null);
            state.payload = null;
        }
    }
});

export const { setPayload, deletePayload } = authenticationCache.actions;

export default authenticationCache.reducer;